import "../style.scss";
import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import {
  IonButton,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
  IonText,
} from "@ionic/react";
import { Box, Stack } from "@mui/material";
import { ShiftContext } from "@src/app/components/workWithFriends/interface";
import {
  fetchNumFamiliarColleaguesAndFriendsShown,
  getNumberOfOpenShiftsInSlot,
} from "@src/app/components/workWithFriends/utils";
import { WorkWithFriends } from "@src/app/components/workWithFriends/workWithFriends";
import { PreviouslyUnassignedAlert } from "@src/app/dayView/Alerts";
import CountDownTimer from "@src/app/dayView/shiftItem/CountDownTimer";
import { MINIMUM_TIME_FOR_MANDATORY_BREAK_IN_HOURS } from "@src/app/hcpShifts/constants";
import { useWorkerQualifications } from "@src/app/licenseManager/hooks/useWorkerQualifications";
import { UrgencyType } from "@src/app/openShifts/urgentShifts/constants";
import { NegotiationStatus } from "@src/app/rateNegotiation/interfaces";
import { ShiftActionButton } from "@src/app/ShiftAction";
import { useAppSelector } from "@src/app/store";
import { useDefinedAgent } from "@src/app/store/helperHooks";
import { getShiftFacilityDistanceText, isShiftPriorityValid } from "@src/app/utils/shiftHelper";
import { useIsExtraTimePayEnabled } from "@src/appV2/Facilities/api/useIsExtraTimePayEnabled";
import { ExtraTimePayBottomSheet } from "@src/appV2/Facilities/components/ExtraTimePayBottomSheet";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { logEvent } from "@src/appV2/lib/analytics";
import { ShiftSearchMode } from "@src/appV2/OpenShifts/ShiftAction/api/useClaimShift";
import { ShiftSpecificationAlert } from "@src/appV2/Shifts/Shift/ShiftSpecification/ShiftSpecificationAlert";
import { StreakAlertWrapper } from "@src/appV2/Streaks/components/StreakAlertWrapper";
import { useGetStreakAlertInfo } from "@src/appV2/Streaks/hooks/useGetStreakAlertInfo";
import { useGetStreaksSegmentData } from "@src/appV2/Streaks/useGetStreaksSegmentData";
import { SEARCH_MODE, USER_EVENTS } from "@src/constants";
import useOnScreen from "@src/hooks/useOnScreen";
import { Facility, FacilityTypes, Shift } from "@src/lib/interface";
import {
  FULL_ZONE_NAMES,
  getHumanReadableTime,
  getOffsetFormattedTextBetweenTimezones,
  minutesToHm,
  timeRangeFormatter,
} from "@src/lib/utils";
import { isBefore, parseISO } from "date-fns";
import {
  homeOutline as homeOutlineIcon,
  locationOutline as locationOutlineIcon,
  stopwatchOutline as stopwatchOutlineIcon,
} from "ionicons/icons";
import moment from "moment-timezone";
import { useEffect, useMemo, useRef, useState } from "react";

import { LunchBreakInformationDialog } from "./components/MandatoryLunchBreakDialog";
import { MaxWorkedHoursWarning } from "./components/MaxWorkedHoursWarning/MaxWorkedHoursWarning";
import { RateNegotiationButton } from "./components/RateNegotiation/Button";
import { ShiftDurationWithAction } from "./components/ShiftDurationWithAction";
import { PayRateText } from "./PayRateText";
import { ShiftItemStatusLine } from "./ShiftItemStatusLine";
import { ShiftPayRate } from "./shiftPayRate";
import { TIME_FORMAT } from "../constants";
import { useMissingDocuments } from "../custom-hooks/useMissingDocuments";
import { ShiftItemViewProps } from "../model";

const ShiftItemView = (props: ShiftItemViewProps) => {
  const {
    shiftDetails,
    onClickOnFacilityDetails,
    isInstantPayAllowed,
    showFacilityDetails,
    isUrgentShift,
    priorityShift,
    setPriorityShift,
    shiftSlot,
    isRateNegotiationActive,
    updateShiftFields,
    allowedAgentReq,
    searchMode,
    shiftBookability,
    isShiftBookabilityLoading = false,
    onBookShift,
    onShiftMarkInterest,
    isForFacilityOpenShift,
  } = props;

  const [shift, setShift] = useState<Shift>(shiftDetails);

  const {
    modalIsOpen: mandatoryLunchDialogIsOpen,
    openModal: openMandatoryLunchDialog,
    closeModal: closeMandatoryLunchDialog,
  } = useModalState();
  const extraTimePayBottomSheetModalState = useModalState();
  const previouslyUnassignedAlertState = useModalState();

  const agent = useDefinedAgent();

  const agentIsBookedForShift = shift.agentId === agent?.userId;
  const agentIsInterestedInShift = isDefined(
    shift?.interested?.find((agentId) => agentId === agent?.userId)
  );

  const isBookingEnabled =
    !agentIsBookedForShift &&
    !agentIsInterestedInShift &&
    (!isRateNegotiationActive || shift?.rateNegotiation?.status !== NegotiationStatus.OPEN) &&
    (!shift?.shiftTimeNegotiationActive ||
      (shift?.shiftTimeNegotiationActive &&
        shift?.rateNegotiation?.status !== NegotiationStatus.OPEN));

  useEffect(() => {
    setShift(shiftDetails);
  }, [shiftDetails]);

  const workerQualifications = useWorkerQualifications();

  let context = ShiftContext.FACILITY;

  if (showFacilityDetails) {
    if (agentIsBookedForShift) {
      context = ShiftContext.MY_SHIFTS;
    } else {
      context = ShiftContext.CALENDAR;
    }
  }

  const elementRef = useRef<HTMLIonGridElement>(null);
  const isOnScreen = useOnScreen(elementRef, 0.8);
  /**
   * FIXME! moment.fn.zoneName is being redefined here
   * This mutates a global import, which means this can silently affect other callers with
   * no clear visibility about where this functionality came from.
   * Note that this would be changed at runtime, and only when this component renders.
   * It is redefined on each iteration.
   */
  moment.fn.zoneName = function () {
    const abbr = this.zoneAbbr();
    return FULL_ZONE_NAMES[abbr] || abbr;
  };
  const ldFlags = useCbhFlags();

  const recentColleaguesData = useAppSelector((state) => state.recentColleaguesStore);
  const { workers: recentColleagues, loading: recentColleaguesLoading } = recentColleaguesData;
  const friendsData = useAppSelector((state) => state.workerFriendsStore.workerFriends);
  const { data: friends, loading: friendsLoading } = friendsData;

  const facilityTypeIconsEnabledMap: Record<FacilityTypes, boolean> =
    ldFlags[CbhFeatureFlag.FACILITY_TYPE_SHIFT_ICONS] ?? {};
  const isMandatoryLunchBreakInfoPopupEnabled =
    ldFlags[CbhFeatureFlag.ENABLE_LUNCH_BREAK_INFO_POPUP];
  const facilityTypeShiftIconsEnabled = shift.facility?.type
    ? facilityTypeIconsEnabledMap[shift.facility.type] ?? false
    : false;

  const [isViewedEventPublishedAlready, setIsViewedEventPublishedAlready] = useState(false);

  const { pendingDocuments, missingDocuments } = useMissingDocuments(shift);
  const hasMissingDocs = missingDocuments.size > 0;

  const shiftFacilityDistanceText = useMemo(() => {
    return getShiftFacilityDistanceText(shift, agent, isUrgentShift ?? false);
    // only trigger a re-evaluation when the agent.location changes not the full agent object
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shift, agent?.geoLocation, isUrgentShift]);

  const isExtraTimePayEnabled = useIsExtraTimePayEnabled(shift.facility);

  const { tmz: facilityTimezone } = shift.facility as Facility;
  const { tmz: agentTimezone } = agent;

  const getShiftTimeInformation = () => {
    if (
      isUrgentShift &&
      (shift.urgency === UrgencyType.NCNS ||
        (shift.urgency === UrgencyType.LATE_CANCELLATION && shift.extendBookabilityTo)) &&
      isDefined(shift.start) &&
      isBefore(parseISO(shift.start), new Date())
    ) {
      return `Leave now and work until ${moment(shift.end)
        .tz(String(facilityTimezone))
        .format("MMMM DD, hh:mm A")}`;
    }

    let text = `${timeRangeFormatter(
      TIME_FORMAT,
      {
        start: shift.start as string,
        end: shift.end as string,
      },
      facilityTimezone
    )}`;
    let shiftDuration = "";
    if (shift.time) {
      shiftDuration = getHumanReadableTime(shift.time);
    }
    if (isUrgentShift) {
      text = `${moment(shift.start).tz(String(facilityTimezone)).format("ddd MMMM DD")} ${text}`;
    }

    return (
      <>
        {text}{" "}
        <ShiftDurationWithAction
          shiftDuration={shiftDuration}
          isInfoButtonVisible={
            shift?.facility?.requiresLunchBreak &&
            isMandatoryLunchBreakInfoPopupEnabled &&
            (shift?.time ?? 0) > MINIMUM_TIME_FOR_MANDATORY_BREAK_IN_HOURS
          }
          onInfoButtonClick={() => {
            openMandatoryLunchDialog();
            logEvent(USER_EVENTS.VIEWED_MANDATORY_UNPAID_BREAK_MODAL, {
              shiftId: shift?._id,
              timestamp: new Date(),
            });
          }}
        />
      </>
    );
  };

  const priorityTillDuration = priorityShift
    ? minutesToHm(moment(shift.priorityTill).diff(moment.utc(), "minutes"))
    : { hours: 0, minutes: 0 };

  const { shouldEmitSegmentEvent, streaksBonus } = useGetStreaksSegmentData(agent?.userId);
  const { message: streakAlertMessage } = useGetStreakAlertInfo({
    shiftStartTime: shift.start ? parseISO(shift.start) : undefined,
    shiftEndTime: shift.end ? parseISO(shift.end) : undefined,
    workerId: agent?.userId ?? "",
    workplaceId: shift.facility?.userId ?? "",
    onlyCheckActiveStreak: false,
    isOpenShift: !isDefined(shift.agentId),
    isForFacilityOpenShift,
  });

  useEffect(() => {
    if (
      isViewedEventPublishedAlready ||
      !shift ||
      !isDefined(shiftBookability) ||
      !agent ||
      hasMissingDocs === undefined ||
      friendsLoading ||
      recentColleaguesLoading ||
      !isOnScreen ||
      agentIsBookedForShift
    ) {
      return;
    }
    const { numFamiliarColleaguesShown, numFriendsShown } =
      fetchNumFamiliarColleaguesAndFriendsShown(
        recentColleagues,
        friends,
        shiftSlot?.bookedBy ?? []
      );
    const streaksMetaData = shouldEmitSegmentEvent ? { streaksBonus, streakAlertMessage } : {};
    logEvent(USER_EVENTS.OPEN_SHIFT_VIEWED, {
      start: shift?.start,
      end: shift?.end,
      facility_id: shift?.facilityId,
      shift_id: shift?.shiftId,
      worker_msa: agent.address?.metropolitanStatisticalArea,
      hourly_pay: shift?.pay,
      agent_req: shift?.agentReq,
      shift_type: shift?.name,
      num_open_shifts_in_slot: getNumberOfOpenShiftsInSlot(shiftSlot?.stats ?? {}),
      num_colleagues_shown: shiftSlot?.bookedBy?.length,
      has_missing_documents: hasMissingDocs,
      has_profile_picture_uploaded: !!agent.isProfilePictureUploaded,
      worker_is_hidden_as_colleague_on_shifts: !!agent.hideAsColleagueOnShifts,
      num_familiar_colleagues_shown: numFamiliarColleaguesShown ?? 0,
      context,
      num_friends_shown: numFriendsShown ?? 0,
      hasExtraTimePay: isExtraTimePayEnabled,
      isPriorityAccessShift: shift.priorityTill ? isShiftPriorityValid(shift.priorityTill) : false,
      priorityAccessTimeRemaining: shift.priorityTill
        ? moment.utc().diff(moment(shift.priorityTill), "minutes")
        : 0,
      facilityDistance: shift.facility?.distance,
      isHolidayShift: shift.isHolidayShift,
      isInstantPay: shift.isInstantPay,
      workplaceMSA: shift.facility?.fullAddress?.metropolitanStatisticalArea,
      distance: shift.distance,
      agentId: agent.userId,
      shiftBookability: shiftBookability ?? {},
      shiftOfferId: shift.offer?.id,
      ...streaksMetaData,
    });
    setIsViewedEventPublishedAlready(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    recentColleaguesLoading,
    shiftSlot,
    shift,
    hasMissingDocs,
    agent,
    friendsLoading,
    isOnScreen,
    shiftBookability,
  ]);

  useEffect(() => {
    if (
      (isRateNegotiationActive || shift?.shiftTimeNegotiationActive) &&
      shift._id &&
      !shift?.rateNegotiation
    ) {
      logEvent(USER_EVENTS.NEGOTIABLE_SHIFT_FOUND, {
        shiftId: shift._id,
        context: SEARCH_MODE.DATE,
      });
    }
  }, [shift, isRateNegotiationActive]);

  const agentReqTagText = useMemo(() => {
    if (allowedAgentReq?.length && workerQualifications?.length) {
      if (
        shift?.agentReq &&
        (allowedAgentReq.includes("all") || allowedAgentReq.includes(shift.agentReq)) &&
        workerQualifications.includes(shift.agentReq)
      ) {
        return shift.agentReq;
      } else if (
        shift?.agentReq === "NURSE" &&
        (allowedAgentReq.includes("all") || allowedAgentReq.includes(shift.agentReq)) &&
        (workerQualifications.includes("LVN") || workerQualifications.includes("RN"))
      ) {
        return agent?.preference?.qualification ?? "";
      }
    }
    return "";
  }, [shift?.agentReq, allowedAgentReq, workerQualifications, agent?.preference?.qualification]);

  return (
    <>
      <IonGrid ref={elementRef}>
        <IonRow className="shift-ion-time-range-text ion-label">
          <IonCol size="12">
            <Box>{getShiftTimeInformation()}</Box>
            {agentTimezone &&
              facilityTimezone &&
              moment(shift.start).tz(agentTimezone).utcOffset() !==
                moment(shift.start).tz(facilityTimezone).utcOffset() && (
                <IonLabel
                  className="ion-text-wrap"
                  style={{
                    background: "#D88C33",
                    borderRadius: "4px",
                    width: "fit-content",
                    padding: "1px 4px",
                    color: "#FFFFFF",
                    fontWeight: 600,
                  }}
                >
                  {`${moment()
                    .tz(String(facilityTimezone))
                    .zoneName()} (${getOffsetFormattedTextBetweenTimezones(
                    shift.start as string,
                    agentTimezone as string,
                    facilityTimezone as string
                  )})`}
                </IonLabel>
              )}
          </IonCol>
        </IonRow>
        {shift?.rateNegotiation?.status !== NegotiationStatus.OPEN && (
          <IonRow>
            <IonCol size="12">
              <PayRateText
                totalPay={
                  <ShiftPayRate shift={shift} isUrgentShift={isUrgentShift} ldFlags={ldFlags} />
                }
                isInstantPay={isInstantPayAllowed}
              />
            </IonCol>
          </IonRow>
        )}
        {showFacilityDetails && shift.isHolidayShift && (
          <IonChip color="dark" className="small-chip">
            <IonLabel>It&apos;s {shift.holidayName}</IonLabel>
          </IonChip>
        )}
        <Stack spacing={1}>
          {(Boolean(agentReqTagText) || facilityTypeShiftIconsEnabled) && (
            <IonRow onClick={() => onClickOnFacilityDetails()}>
              <IonCol size="12">
                {Boolean(agentReqTagText) && (
                  <IonChip color="primary" className="small-chip small-chip-button">
                    <IonButton fill="clear" disabled size="small" className="tag-type-clip-button">
                      <span className="tag-type-clip-text" data-testid="agent-req-chip">
                        {agentReqTagText}
                      </span>
                    </IonButton>
                  </IonChip>
                )}
                {facilityTypeShiftIconsEnabled && (
                  <IonChip color="primary" className="small-chip small-chip-button">
                    <IonButton fill="clear" disabled size="small" className="tag-type-clip-button">
                      <span className="tag-type-clip-text">{shiftDetails.facility?.type}</span>
                    </IonButton>
                  </IonChip>
                )}
              </IonCol>
            </IonRow>
          )}
          {isDefined(shift.start) &&
            isDefined(shift.end) &&
            isDefined(agent?.userId) &&
            isDefined(shift.facility) &&
            isDefined(shift.facility.userId) && (
              <StreakAlertWrapper
                shiftStartTime={parseISO(shift.start)}
                shiftEndTime={parseISO(shift.end)}
                workerId={agent.userId}
                workplaceId={shift.facility.userId}
                onlyCheckActiveStreak={false}
                isOpenShift={!isDefined(shift.agentId)}
                isForFacilityOpenShift={isForFacilityOpenShift}
                shiftId={shift._id}
                isUrgentShift={isUrgentShift}
              />
            )}
          {showFacilityDetails && (
            <IonRow onClick={() => onClickOnFacilityDetails()} role="button">
              <IonCol size="12" className="shift-item__description">
                <IonText>
                  <h3
                    className={`shift-item__title-link${
                      facilityTypeShiftIconsEnabled ? " no-margin-top" : ""
                    }`}
                  >
                    {shift?.facility?.name}
                  </h3>
                </IonText>
                <IonText>
                  <p className="address-text">{shift?.facility?.fullAddress?.formatted}</p>
                </IonText>
                <IonRow className="etp-dist-video-wrapper ion-align-items-center">
                  {shiftFacilityDistanceText && (
                    <IonText>
                      <p className="distance-text">
                        <IonIcon
                          className="icon distance-icon"
                          icon={isUrgentShift ? locationOutlineIcon : homeOutlineIcon}
                          color={isUrgentShift ? "#828282" : undefined}
                        />
                        {shiftFacilityDistanceText}
                      </p>
                    </IonText>
                  )}
                  {isExtraTimePayEnabled && (
                    <IonText
                      onClick={(event) => {
                        event.stopPropagation();
                        extraTimePayBottomSheetModalState.openModal();
                        logEvent(USER_EVENTS.VIEWED_EXTRA_TIME_PAY_ALERT, {
                          workerId: agent.userId,
                        });
                      }}
                    >
                      <p className="facility-info-link">
                        <IonIcon size="small" icon={stopwatchOutlineIcon} /> Extra Time Pay Facility
                      </p>
                    </IonText>
                  )}
                </IonRow>
              </IonCol>
            </IonRow>
          )}
        </Stack>
        {!agentIsBookedForShift && agentIsInterestedInShift && (
          <IonRow>
            <IonCol size="12" text-center className="shift-item__text-waiting-confirmation">
              Shift Request Sent, Waiting for Confirmation
            </IonCol>
          </IonRow>
        )}
        {!hasMissingDocs && !pendingDocuments.size && shiftSlot && (
          <WorkWithFriends shiftSlot={shiftSlot} shift={shift} context={context} />
        )}
        {showFacilityDetails && agentIsBookedForShift && (
          <IonRow>
            <IonCol size="12" className="shift-item__work-confirmed">
              <ShiftItemStatusLine showSentHomeMessage={shift.hasSentHomeRequest} />
            </IonCol>
          </IonRow>
        )}

        {isDefined(shift.shiftSpecifications) && shift.shiftSpecifications.length > 0 && (
          <Stack justifyContent="center" marginY={1} marginRight={1}>
            <ShiftSpecificationAlert shiftSpecifications={shift.shiftSpecifications[0]} />
          </Stack>
        )}

        {isBookingEnabled && (
          <Stack justifyContent="center" marginY={2} marginRight={1} spacing={2}>
            <MaxWorkedHoursWarning shiftBookability={shiftBookability} facility={shift?.facility} />
            <ShiftActionButton
              shift={shift}
              shiftBookability={shiftBookability}
              isUrgentShift={isUrgentShift ?? false}
              isPriorityShift={priorityShift ?? false}
              isShiftBookabilityLoading={isShiftBookabilityLoading}
              searchMode={searchMode as unknown as ShiftSearchMode}
              onBookShift={(bookedShift) => {
                // Manually update the shift state so the booked shift is labelled with "You're working this shift"
                setShift({ ...shift, agentId: bookedShift.agentId });
                onBookShift(bookedShift);
              }}
              onShiftMarkInterest={() => {
                if (isDefined(agent?.userId)) {
                  setShift({
                    ...shift,
                    interested: isDefined(shift?.interested)
                      ? [...shift.interested, agent?.userId]
                      : [agent?.userId],
                  });
                }
                onShiftMarkInterest();
              }}
            />
          </Stack>
        )}
        {!agentIsBookedForShift && priorityShift && (
          <IonRow className="ion-align-items-center ion-justify-content-center">
            <IonLabel className="ion-label" color="shift-priority">
              <h4 data-testid="priority-access-counter">
                Priority Access expires in{" "}
                <CountDownTimer
                  hours={priorityTillDuration.hours}
                  minutes={priorityTillDuration.minutes}
                  seconds={0}
                  callbackFn={() => setPriorityShift(false)}
                />
              </h4>
            </IonLabel>
          </IonRow>
        )}
        <RateNegotiationButton
          shift={shift}
          isRateNegotiationActive={Boolean(isRateNegotiationActive)}
          openPreviouslyUnassignedAlert={() => previouslyUnassignedAlertState.openModal()}
          isLoadingShiftBook={isShiftBookabilityLoading}
          workApproved={agentIsBookedForShift}
          updateShiftViewRateNegotiation={(updatedShift) => {
            setShift(updatedShift);
            updateShiftFields?.(updatedShift, ["rateNegotiation"]);
          }}
          shiftBookability={shiftBookability}
          onBookShift={(bookedShift) => {
            // Manually update the shift state so the booked shift is labelled with "You're working this shift"
            setShift({ ...shift, agentId: bookedShift.agentId });
            onBookShift(bookedShift);
          }}
        />
      </IonGrid>
      <ExtraTimePayBottomSheet
        facilityUserId={shift.facility!.userId!}
        modalState={extraTimePayBottomSheetModalState}
      />
      <PreviouslyUnassignedAlert modalState={previouslyUnassignedAlertState} />
      {shift?.facility?.name && shift.time ? (
        <LunchBreakInformationDialog
          isOpen={mandatoryLunchDialogIsOpen}
          closeModal={closeMandatoryLunchDialog}
          shiftDetails={{
            facilityName: shift?.facility?.name,
            shiftTime: shift.time,
          }}
        />
      ) : null}
    </>
  );
};

export { ShiftItemView };
