import EventBusyIcon from "@mui/icons-material/EventBusy";
import { Alert } from "@mui/material";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import {
  BookabilityAttributes,
  BookabilityDecision,
  BookabilityUnmetCriteria,
} from "@src/appV2/OpenShifts/ShiftAction";
import { Facility } from "@src/lib/interface/src/lib/shift";

interface MaxWorkedHoursProps {
  shiftBookability?: BookabilityAttributes;
  facility?: Facility;
}

const getMaxHoursWarningLabel = (
  shiftBookability: BookabilityAttributes,
  { maxAllowedWorkConsecutiveHours, maxAllowedWorkHoursPerWeek }: Facility
) => {
  if (shiftBookability.decision !== BookabilityDecision.BLOCKED) {
    return undefined;
  }

  if (
    shiftBookability.unmetCriteria.includes(
      BookabilityUnmetCriteria.WORKER_HAS_REACHED_MAX_CONSECUTIVE_HOURS
    ) &&
    maxAllowedWorkConsecutiveHours !== undefined
  ) {
    return `This facility does not allow professionals to book more than ${maxAllowedWorkConsecutiveHours} consecutive hours of work. The limit applies to shifts at this facility only.`;
  }
  if (
    shiftBookability.unmetCriteria.includes(
      BookabilityUnmetCriteria.WORKER_HAS_REACHED_MAX_HOURS_PER_WEEK
    ) &&
    maxAllowedWorkHoursPerWeek !== undefined
  ) {
    return `This facility does not allow professionals to book more than ${maxAllowedWorkHoursPerWeek} hours of work per week. The limit applies to shifts at this facility only.`;
  }
};

export function MaxWorkedHoursWarning({ shiftBookability, facility }: MaxWorkedHoursProps) {
  const allowFacilitiesToSetMaxConsecutiveHoursFlag = useCbhFlag(
    CbhFeatureFlag.ALLOW_FACILITIES_TO_SET_MAX_CONSECUTIVE_HOURS,
    { defaultValue: false }
  );

  if (!allowFacilitiesToSetMaxConsecutiveHoursFlag || !shiftBookability || !facility) {
    return <></>;
  }

  const warningLabel = getMaxHoursWarningLabel(shiftBookability, facility);

  if (!warningLabel) {
    return <></>;
  }

  return (
    <Alert severity="warning" icon={<EventBusyIcon />} aria-label="Max worked hours warning">
      {warningLabel}
    </Alert>
  );
}
